import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import EventTeaser from "Layout/Events/EventTeaser/EventTeaser"
import IntroText from "Layout/IntroText/IntroText"
import VisualNavigation from "Layout/Navigation/VisualNavigation/VisualNavigation"
import TypoOverlay from "Layout/TypoOverlay/TypoOverlay"

const Index = ({
  data,
  location
}) => {

  const {page, menu, events, optionsPage} = data
  const subMenu = menu.edges[0]
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location}>
      <TypoOverlay />
      <IntroText intro={page.acf.intro_text} genericImages={gI} classes="py-5" />
      <EventTeaser events={events} />
      <VisualNavigation items={subMenu} genericImages={gI} />
    </MainLayout>
  )
}

export const query = graphql`
  query homePageQuery {
    page: wordpressPage(wordpress_id: {eq: 74}) {
      title
      wordpress_id
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
      }
    }
    menu: allWordpressWpApiMenusMenusItems(filter: {name: {eq: "Sub Menu"}}) {
      edges {
        node {
          name
          items {
            title
            object_slug
            wordpress_id
          }
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    events: allWordpressWpEventPosts(
      sort: {
        fields: [date], order:DESC
      }
    ) {
      edges {
        node {
          id
          slug
          title
          categories_names {
            name
            taxonomy
          }
          acf {
            event {
              event_subtitle
              speaker_name
              event_venue
              event_date_info {
                event_date_info_end_date
                event_date_info_end_time
                event_date_info_start_date
                event_date_info_start_time
              }
            }
          }
        }
      }
    }
  }
`

export default Index
